@import './vars';
@import '~@storaensods/seeds-core/dist/tokens';

.se-vertical-menu-item-label {
  padding: 0.5rem 0.5rem 0.5rem 1.2rem; }

.se-card:not(.se-card--compact).se-card--positive {
  .se-card-header {
    background-color: $se-color-brand-tint-green;
    .se-card-title {
      color: $se-color-heading-dark; }
    .se-card-subtitle {
      color: $se-color-heading-dark; } } }
.se-card:not(.se-card--compact).se-card--attention {
  .se-card-header {
    background-color: $se-color-brand-tint-yellow; } }

// .se-tile.se-tile--yellow
//   background-color: $se-color-brand-tint-yellow

.se-header-title {
  position: relative; }

.solution-page .solution-sidebar {
  min-height: 90vh;
  height: auto; }

.se-font-size-sm {
  font-size: $se-font-size-sm; }


.Dropdown-group {
  padding: 0 !important;
  .Dropdown-title {
    font-weight: bold;
    padding: $se-spacing-sm; }
  .Dropdown-option {
    cursor: pointer;
    padding: $se-spacing-sm;
    &:hover {
      background-color: $se-color-information-bg; } }

  &:hover {
    background-color: transparent !important; } }

.Dropdown-menu.se-dropdown--menu {
  max-height: 400px;
  overflow: auto; }


.cvp-admin-panel {
  background-color: $se-color-bg-gray-light; }


.width-100 {
  width: 100%; }

textarea, input {

  &::-webkit-input-placeholder {
    color: $se-color-text-dark !important; }

  &:-moz-placeholder {
    color: $se-color-text-dark !important;
    opacity: 1; }

  &::-moz-placeholder {
    color: $se-color-text-dark !important;
    opacity: 1; }

  &:-ms-input-placeholder {
    color: $se-color-text-dark !important; }

  &::-ms-input-placeholder {
    color: $se-color-text-dark !important; }

  &::placeholder {
    color: $se-color-text-dark !important; }

  &:focus {
    &::-webkit-input-placeholder {
      color: $se-color-bg-gray !important; }

    &:-moz-placeholder {
      color: $se-color-bg-gray !important;
      opacity: 1; }

    &::-moz-placeholder {
      color: $se-color-bg-gray !important;
      opacity: 1; }

    &:-ms-input-placeholder {
      color: $se-color-bg-gray !important; }

    &::-ms-input-placeholder {
      color: $se-color-bg-gray !important; }

    &::placeholder {
      color: $se-color-bg-gray !important; } } }

.searchSpacing {
  display: block;
  margin-bottom: 0.5rem;
  white-space: pre-wrap; }

.searchContainer {
  display: flex; }


.Dropdown-control.se-dropdown--control {
  .se-row-item {
    position: absolute;
    width: 100%;
    .se-row-placeholder {
      z-index: 1;
      opacity: 0; }
    .se-row-content {
      z-index: 2;
      top: 0;
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: calc(100% - 50px);
      &--left {
        color: $se-color-heading-dark; }
      &--right {
        color: $se-color-text-dark; } } } }


.Dropdown-menu.se-dropdown--menu {
  .se-row-item {
    position: relative;
    .se-row-placeholder {
      z-index: 1;
      opacity: 0; }
    .se-row-content {
      z-index: 2;
      top: 0;
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      &--left {
        color: $se-color-heading-dark; }
      &--right {
        color: $se-color-text-dark; } } } }
