.row .col-body:not(:first-child) {
  border-left: 1px solid #DBDDE3;
  padding-left: 4rem;
}

.goto-container {
  display: flex;
}

.goto-link {
  margin-left: auto;
  margin-right: 1rem;
}
